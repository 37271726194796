import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";

const PageContainer = ({ title, description, children }) => (
  <div>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
    <Box display="flex" flexDirection="column" gap={3}>
      {children}
    </Box>
  </div>
);

PageContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};

export default PageContainer;
