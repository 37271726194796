import { Button } from "@mui/material";
import { IconUser, IconUsers } from "@tabler/icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PageContainer from "src/components/container/PageContainer";
import GenericTable from "src/components/table/Table";
import PageTitle from "src/components/title/PageTitle";
import { getStudios } from "src/redux/actions/studioActions";
import useTable from "src/utils/useTable";

const StudiosPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    sortBy,
    setSortBy,
    direction,
    setDirection,
    page,
    setPage,
    limit,
    setLimit,
  } = useTable();

  const {
    studios: { data, loading },
  } = useSelector((state) => state.studio);

  useEffect(() => {
    dispatch(
      getStudios({
        page,
        limit,
        sortBy,
        direction,
      })
    );
  }, [dispatch, page, limit, sortBy, direction]);

  const { count, studios } = data || {};

  return (
    <PageContainer title="Studios" description="Studios page">
      <PageTitle
        title="Studios"
        action={
          <Button
            onClick={() => navigate("/studios/create")}
            variant="contained"
          >
            Create studio
          </Button>
        }
      />
      <GenericTable
        title="Studios"
        columns={[
          {
            id: "name",
            label: "Name",
            format: (row) => (
              <Button onClick={() => navigate(`/studios/${row.id}`)}>
                {row.name}
              </Button>
            ),
            sortable: true,
          },
          {
            id: "createdAt",
            label: "Created At",
            format: (row) => new Date(row.createdAt).toLocaleDateString(),
            sortable: true,
          },
          {
            id: "users",
            label: "Users",
            format: (row) => <Link to={`/studios/${row.id}/users`}>
              <IconUsers />
            </Link>,
          }
        ]}
        data={studios || []}
        count={count || 0}
        loading={loading}
        sortBy={sortBy}
        setSortBy={setSortBy}
        direction={direction}
        setDirection={setDirection}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
      />
    </PageContainer>
  );
};

export default StudiosPage;
