import { Box, Modal, Typography } from "@mui/material";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";
import { useEffect, useState } from "react";
import { FileViewer } from "react-file-viewer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageContainer from "src/components/container/PageContainer";
import GenericTable from "src/components/table/Table";
import { getAllStudios } from "src/redux/actions/studioActions";
import { getUsers, updateUser } from "src/redux/actions/userActions";
import useTable from "src/utils/useTable";

const CustomErrorComponent = () => "Can't display this file type";

const DaySubmission = ({ day, submissions, handleSelectedFile }) => {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  return (
    <Box
      key={day}
      sx={{
        height: "120px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "scroll",
      }}
    >
      <Typography variant="body2" sx={{ margin: "0 10px" }}>
        {day} ({submissions[day]?.length}{" "}
        {submissions[day]?.length > 1 ? "submissions" : "submission"})
      </Typography>
      <Box position="relative" height="100px" sx={{ cursor: "pointer" }}>
        {submissions[day]?.length > 1 && (
          <>
            <Box
              position="absolute"
              top="0"
              bottom="0"
              right="0"
              onClick={() =>
                setIndex(index === submissions[day]?.length - 1 ? 0 : index + 1)
              }
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                cursor: "pointer",
                "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
              }}
            >
              <IconChevronRight />
            </Box>
            <Box
              position="absolute"
              top="0"
              bottom="0"
              left="0"
              onClick={() =>
                setIndex(index === 0 ? submissions[day]?.length - 1 : index - 1)
              }
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                cursor: "pointer",
                "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
              }}
            >
              <IconChevronLeft />
            </Box>
          </>
        )}
        <img
          onClick={() =>
            navigate(`/submissions/${submissions[day]?.[index]?.id}`)
          }
          src={submissions[day]?.[index]?.url}
          alt="submission"
          style={{ height: "100px" }}
        />
      </Box>
    </Box>
  );
};

const SubmissionsDisplay = ({ submissions }) => {
  const [url, setUrl] = useState(null);
  const [fileType, setFileType] = useState(null);

  const handleSelectedFile = async (url) => {
    const filesTypes = [
      ".jpg",
      ".jpeg",
      ".png",
      ".gif",
      ".pdf",
      ".mp4",
      ".mp3",
      ".wav",
      ".ogg",
    ];

    for (const fileType of filesTypes) {
      if (url.endsWith(fileType)) {
        setFileType("jpg");
        setUrl(url);
        return;
      }
    }

    const response = await fetch(url);
    const blob = await response.blob();
    const fileType = blob.type.split("/")[1];
    setFileType(fileType);
    setUrl(url);
  };

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      gap="10px"
    >
      <Modal open={url !== null} onClose={() => setUrl(null)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {fileType && url && (
            <FileViewer
              fileType={fileType || "jpg"}
              filePath={
                url ||
                "https://png.pngtree.com/png-clipart/20190918/ourmid/pngtree-load-the-3273350-png-image_1733730.jpg"
              }
              errorComponent={CustomErrorComponent}
            />
          )}
        </Box>
      </Modal>
      {Object.keys(submissions || {})?.map((day) => (
        <DaySubmission
          day={day}
          submissions={submissions}
          handleSelectedFile={handleSelectedFile}
        />
      ))}
    </Box>
  );
};

const StudioUsersPage = () => {
  const dispatch = useDispatch();
  const { id: studioId } = useParams();

  const {
    users: { data, loading },
    allStudios,
  } = useSelector((state) => ({
    users: state.user.users,
    allStudios: state.studio.allStudios.data,
  }));

  const {
    sortBy,
    setSortBy,
    direction,
    setDirection,
    page,
    setPage,
    limit,
    setLimit,
  } = useTable();

  const handleUserRoleChange = (userId, role) => {
    dispatch(updateUser({ id: userId, role }));
  };

  useEffect(() => {
    dispatch(getAllStudios());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getUsers({
        page,
        limit,
        sortBy,
        direction,
        studioId,
      })
    );
  }, [dispatch, page, limit, sortBy, direction, studioId]);

  const { users, count } = data || {};

  return (
    <PageContainer title="Users" description="Users page">
      <GenericTable
        title="Users"
        columns={[
          {
            id: "name",
            label: "Name",
            format: (row) => row.name,
            sortable: true,
          },
          {
            id: "submissions",
            label: "Submissions",
            format: (row) => (
              <SubmissionsDisplay submissions={row.submissions} />
            ),
          },
        ]}
        data={users}
        count={count || 0}
        loading={loading}
        sortBy={sortBy}
        setSortBy={setSortBy}
        direction={direction}
        setDirection={setDirection}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
      />
    </PageContainer>
  );
};

export default StudioUsersPage;
