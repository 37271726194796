import {
  Box, Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@mui/material";
import { IconChevronDown, IconChevronUp } from "@tabler/icons";
import DashboardCard from "../shared/DashboardCard";

const GenericTable = ({
  title,
  columns,
  action,
  data,
  count,
  loading,
  sortBy,
  setSortBy,
  direction,
  setDirection,
  page,
  setPage,
  limit,
  setLimit,
}) => {
  return (
    <DashboardCard title={title} action={action}>
      <Box sx={{ overflow: "auto", width: { xs: "280px", sm: "auto" } }}>
        <Table
          aria-label="simple table"
          sx={{
            whiteSpace: "nowrap",
            mt: 2,
          }}
        >
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  onClick={() => {
                    if (!column.sortable) return;

                    setSortBy(column.id);

                    if (column.id === sortBy) {
                      setDirection(direction === "asc" ? "desc" : "asc");
                    } else {
                      setDirection("asc");
                    }
                  }}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  <Box display="flex" alignItems="flex-end" overflow="visible">
                    <Typography variant="subtitle2" fontWeight={600}>
                      {column.label}
                    </Typography>
                    {column.id === sortBy &&
                      (direction === "asc" ? (
                        <IconChevronUp />
                      ) : (
                        <IconChevronDown />
                      ))}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading &&
              Array(10)
                .fill(0)
                .map((_, i) => (
                  <TableRow>
                    {columns?.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        <Skeleton variant="rounded" sx={{ height: 20 }} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            {!loading &&
              data?.map((row) => (
                <TableRow key={row.name}>
                  {columns?.map((column) => (
                    <TableCell>{column.format(row)}</TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={limit}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={(e) => setLimit(e.target.value)}
        />
      </Box>
    </DashboardCard>
  );
};

export default GenericTable;
